<!-- 胡金榜 2.5.7 -->
<template>
  <div ref="parent" class="page-add">
    <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add">
      <a-form-item label="流程名称" name="processName" class="form-item">
        <a-input
          :maxlength="30"
          v-model:value="modalVal.processName"
          placeholder="请输入流程名称"
          class="form-item-width"
        />
      </a-form-item>
      <a-form-item label="流程类型" name="process" class="form-item">
        <a-radio-group v-model:value="modalVal.process" @change="handleProcessTypeChange" class="form-item-width">
          <a-radio :value="1"> 固定流程 </a-radio>
          <!-- <a-radio :value="2"> 自由流程 </a-radio> -->
        </a-radio-group>
      </a-form-item>
      <a-form-item
        v-if="modalVal.process === 1"
        label="审批流程"
        name="staffList"
        :class="modalVal.staffList.length ? 'form-item form-item-error' : 'form-item'"
      >
        <StaffProcess
          @open-approval-modal="openApprovalModal"
          @delete-approval="deleteApprovalFn"
          :approvalList="modalVal.staffList"
          :isAdd="true"
          class="form-item-width"
        />
      </a-form-item>
      <section v-else class="free-tips">
        <!-- <p class="title">
          <svg>
            <use xlink:href="#iconWaring"></use></svg
          >温馨提示
        </p> -->
        <p class="text">
          自由流程允许人员在提交用印申请时自行选择审批人，请知晓此流程可能带来的风险，并规定好适用范围。
        </p>
      </section>

      <!-- 抄送人 -->
      <a-form-item label="抄送人" name="informPeople" class="form-item">
        <div class="form-tips-view">
          <div
            @click="handleDepartment(3)"
            class="printer"
            :style="{
              padding: modalVal.informPeople.length ? '6px 24px 1px 10px' : '0 0 0 12px',
              'line-height': modalVal.informPeople.length ? 'auto' : '32px',
              maxHeight: '100px',
              overflow: 'auto',
            }"
          >
            <template v-if="modalVal.informPeople.length">
              <div v-for="(item, index) in modalVal.informPeople" :key="item.dataId" class="pt-tag">
                <span class="pt-name" :title="item.dataName">{{ item.dataName }}</span>
                <div @click.stop="deleteSta(index, 3)" class="pt-close">
                  <CloseOutlined />
                </div>
              </div>
            </template>
            <span v-else>请选择抄送人</span>
            <DownOutlined class="down-arrow" />
          </div>
        </div>
      </a-form-item>

      <a-form-item label="" class="form-item">
        <section style="margin-left: 82px">
          <a-button class="cancel-btn" @click="handleCancel">取消</a-button>
          <a-button class="submit-btn" @click="comfirmAdd" :loading="loading">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
    <a-modal
      :width="655"
      centered
      title="选择审批人"
      @cancel="closeApprovaModel"
      @ok="submit"
      :visible="approvalVisible"
      :getContainer="() => $refs.parent"
    >
      <a-radio-group v-model:value="approvalType" :default-value="1" @change="radioChange" style="margin-bottom: 20px">
        <a-radio :value="1">指定人员</a-radio>
        <a-radio :value="2" v-if="!isHavLeader">部门主管</a-radio>
      </a-radio-group>
      <a-row class="approval-modal" v-if="approvalType == 1">
        <a-col :span="12" style="padding: 16px 0 16px 14px; border-right: 1px solid rgba(0, 0, 0, 0.06)">
          <section style="margin-bottom: 24px" v-if="check.totalSearchList.length - modalVal.staffList.length">
            <a-input
              class="search-input"
              style="width: 190px; margin-right: 17px"
              :maxlength="10"
              v-model:value="check.searchLbwVal"
              placeholder="请输入姓名查询"
            >
              <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
            </a-input>
            <a-button type="primary" @click="searchApprovalList" style="border-radius: 2px">查找</a-button>
          </section>
          <div style="overflow-y: scroll" v-show="check.searchLbwList.length">
            <!-- <a-checkbox :indeterminate="check.indeterminate" :checked="check.checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox> -->
            <a-checkbox-group :value="check.checkedList">
              <Virtual :list="check.searchLbwList" :size="62" :remain="8" :isScrollTop="isVirtualListScroll">
                <template #default="{ item, index }">
                  <StaffItem :item="item" :index="index" :onChange="onChange" />
                </template>
              </Virtual>
            </a-checkbox-group>
          </div>
          <div class="no-data" v-show="!check.searchLbwList.length">
            <a-empty :image="simpleImage" class="no-icon" />
          </div>
        </a-col>
        <a-col :span="12" style="padding: 16px 0 16px 14px">
          <p style="color: rgba(0, 0, 0, 0.85); margin-bottom: 24px; font-size: 14px">以下人员拥有该审批的管理权限</p>
          <div class="scroll">
            <StaffList :list="check.checkedListRight" :onChangeList="onChangeList" />
          </div>
        </a-col>
      </a-row>
      <div v-else style="display: flex">
        <div style="margin-right: 10px">找不到部门主管时，由上级部门主管代审批</div>
        <a-switch checked-children="启" un-checked-children="禁" v-model:checked="switchValue" @change="switchChange" />
      </div>
    </a-modal>

    <!-- 抄送人弹框 -->
    <RangeModal
      :selectData="departmentList"
      :visible="RangeModalVisible"
      :title="popText"
      @close="handledRangeVisible"
      @checkData="checkData"
      ref="sonRef"
      :type="type"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue'
import ScopeTab from './components/scopeTab.vue'
import {
  getProcessStaffList,
  validateProcessName,
  getDepartmentOrganizational,
  addProcess,
  getDepartmentShow,
} from '@/apis/businessManage'
import VirtualList from '@/components/VirtualList'
import Virtual from '@/components/VirtualList/virtualList'
// import ApprovalStaffItem from '@/components/VirtualList/approvalStaffItem'
import StaffItem from './components/staffItem'
import StaffList from './components/staffList'
import StaffProcess from './components/staffProcess'
import { useCheckStateAndEvent } from '@/utils/hooks'
import ApprovalStaffList from '@/views/components/approvalStaffList'
import '@/style/page-add.scss'
import { useRouter } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import { Empty } from 'ant-design-vue'
import { string } from 'vue-types'
import RangeModal from '@/components/rangeModal'
import RangeBox from './components/rangeBox.vue'
import { DownOutlined, CloseOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    ScopeTab,
    VirtualList,
    // ApprovalStaffItem,
    StaffItem,
    ApprovalStaffList,
    StaffList,
    StaffProcess,
    Virtual,
    Empty,
    RangeBox,
    DownOutlined,
    RangeModal,
    CloseOutlined
  },
  setup(props, ctx) {
    const router = useRouter()
    const formRef = ref()
    const store = useStore()
    const modalVal = reactive({
      processName: undefined,
      // business: 1, // 业务类型
      process: 1, // 流程类型
      department: undefined, // 适用范围
      departmentVal: {
        // 适用范围的选择值
        staff: [],
        department: [],
      },
      staffList: [],
      informPeople: [], // 抄送人
    })
    const approvalFormRef = ref()
    const approvalModel = reactive({
      approvalName: undefined,
    })
    const approvalRules = {
      approvalName: [
        {
          type: 'number',
          required: true,
          message: '请选择审批人',
        },
      ],
    }
    const state = reactive({
      expandedKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      approvalVisible: false,
      isSelectopen: false, // 是否打开流程范围的下拉框
      isFocus: true, // 是否流程范围获取焦点
      isClickTab: true, // 是否点的时流程范围的下拉框上
      // scopeVisible: props.visible, // 流程范围下拉框的数据刷新的作用
      organizationalList: [],
      isAllFlowScope: false,
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0,
      loading: false,
      approvalType: 1, //审批人类型
      switchValue: false,
      isHavLeader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      popText: '', //弹框文字
      departmentList: [], //使用范围列表
      RangeModalVisible: false, // 弹框开关
      type: undefined, // 抄送人类型
    })
    const rules = ref()
    rules.value = {
      processName: [
        {
          required: true,
          message: '请输入流程名称',
        },
      ],
      process: [
        {
          type: 'number',
          required: true,
          message: '请选择流程类型',
        },
      ],
      staffList: [
        {
          type: 'array',
          required: true,
          message: '请选择审批流程',
        },
      ],
    }
    const range = reactive({
      value: [],
      options: [],
    })
    const departmentArr = computed(() => {
      const data = []
      const getChildren = (arr) => {
        arr.forEach((it) => {
          data.push({ id: it.id, departmentName: it.departmentName })
          if (it.departmentDTOList.length > 0) getChildren(it.departmentDTOList)
        })
      }
      getChildren(state.organizationalList)
      return data
    })

    watch(
      () => modalVal.departmentVal,
      (val) => {
        range.value = []
        range.options = []
        val.staff.forEach((staffId) => {
          const r = check.totalSearchList.find((it) => staffId === it.id)
          if (!r) return
          range.value.push(staffId)
          range.options.push({ key: `s-${staffId}`, value: staffId, label: r.staffName, disabled: true })
        })
        console.log('val.department', val.department)
        val.department.forEach((departmentId) => {
          const r = departmentArr.value.find((it) => departmentId === it.id)
          if (!r) return
          range.value.push(departmentId)
          range.options.push({ key: `d-${departmentId}`, value: departmentId, label: r.departmentName, disabled: true })
        })
      },
      { deep: true }
    )

    // 选择抄送人唤起弹框
    const handleDepartment = async type => {
      state.type = type
      state.RangeModalVisible = true
      if (type === 3) {
        state.departmentList = modalVal.informPeople
        state.popText = '选择抄送人'
      }
    }
    // 删除其中一项
    const deleteSta = (index, type) => {
      if (type === 3) {
        modalVal.informPeople.splice(index, 1)
      }
    }
    // 抄送人点确定
    const checkData = e => {
      console.log('modalVal.ScopeApplication', e)
      if (state.type === 3) {
        modalVal.informPeople = e
      }
    }
    // 抄送人弹框关闭
    const handledRangeVisible = e => {
      state.RangeModalVisible = e
    }

    const validateProcessNameFn = async () => {
      // 校验流程名字是否重复
      // if (props.types === 'edit') {
      //   return { success: true }
      // }
      const res = await validateProcessName({ flowName: modalVal.processName })
      return res
    }

    const enhancerMapFn = {
      closeApprovaModel() {
        state.approvalVisible = false
      },
      selectApprovalFn() {
        state.approvalVisible = false
        setTimeout(() => {
          formRef.value.clearValidate('staffList')
          state.isVirtualListScroll2 += '1'
        })
      },
      deleteApprovalFn() {
        state.isVirtualListScroll2 = {}
      },
      searchApprovalList() {
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
      },
      openApprovalModal() {
        state.approvalVisible = true
        state.approvalType = 1
        if (modalVal.staffList.length > 0) {
          let tmp = modalVal.staffList.filter((val) => val.type === 2)
          if (tmp.length === 0) {
            state.isHavLeader = false
          } else {
            state.isHavLeader = true
          }
        } else {
          state.isHavLeader = false
        }
        setTimeout(() => {
          typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
        })
      },
      changeStaffList() {
        modalVal.staffList = check.staffList.map((item) => item)
      },
    }
    const {
      check,
      onChange,
      onCheckAllChange,
      reloadSearchLbwList,
      selectApprovalFn,
      searchApprovalList,
      deleteApprovalFn,
      closeApprovaModel,
      openApprovalModal,
      initialApproalList,
      onChangeList,
    } = useCheckStateAndEvent(enhancerMapFn)

    const comfirmAdd = () => {
      // 提交函数
      // emit('update:loading', true)
      console.log('抄送人数据', modalVal.informPeople);
      state.loading = true
      formRef.value
        .validate()
        .then(async () => {
          const params = {}
          const res = await validateProcessNameFn()
          if (!res.success) {
            state.loading = false
            return
          }
          params.flowName = modalVal.processName
          params.flowType = modalVal.process
          params.businessType = modalVal.business

          // 抄送人数据处理
          let flowUserRelCCList = modalVal.informPeople.map((item) => {
            return {
              staffId: item.dataId
            }
          })
          params.flowUserRelCCList = flowUserRelCCList

          // console.log(state.approvalType,state.switchValue,modalVal.staffList)
          if (modalVal.process === 1) {
            params.flowUserRelsDTO = modalVal.staffList.map((item) => {
              let tmp = {
                staffId: item.staffId == null ? null : item.id || item.staffId,
                type: item.staffId == null ? 2 : 1,
              }
              // console.log(item.upDpLook?3:2)
              return tmp
            })
          }
          let tmp = modalVal.staffList.filter((val) => val.staffId == null)
          if (tmp.length > 0) {
            params.flowSupervisor = tmp[0].upDpLook ? 3 : 2
          } else {
            params.flowSupervisor = 1
          }
          // v2.5.7 去掉适用范围，默认传1
          params.flowScope = 1
          // if (
          //   check.totalSearchList.length === modalVal.departmentVal.staff.length ||
          //   (!modalVal.departmentVal.staff.length && !modalVal.departmentVal.department.length)
          // ) {
          //   params.flowScope = 1
          //   params.flowScopeVO = []
          // } else {
          //   iterationProcessRange(params, modalVal)
          // }
          modalSubmit(params)
        })
        .catch(() => (state.loading = false))

      function iterationProcessRange(params, modalVal) {
        // 处理 选择流程范围把值提取出来的函数
        params.flowScope = 2
        params.flowScopeDTO = []
        modalVal.departmentVal.department.length &&
          (modalVal.departmentVal.department = modalVal.departmentVal.department.filter(
            (item) => typeof item === 'number'
          ))
        modalVal.departmentVal.staff.length > modalVal.departmentVal.department.length
          ? modalVal.departmentVal.staff.forEach((item, index) => {
              // console.log(item, 'item')
              params.flowScopeDTO[index] = {
                staffId: item,
                departmentId: modalVal.departmentVal.department[index],
              }
            })
          : modalVal.departmentVal.department.forEach(
              (item, index) =>
                (params.flowScopeDTO[index] = { departmentId: item, staffId: modalVal.departmentVal.staff[index] })
            )
        !params.flowScopeDTO.length ? (params.flowScope = 1) : null
      }
    }
    const getProcessStaff = async () => {
      // 获取流程的审批人
      const res = await getProcessStaffList()
      check.searchLbwList = res.data.map((item) => ({ ...item, label: item.name, value: item.id }))
      check.totalSearchList = res.data.map((item) => ({ ...item, label: item.name, value: item.id }))
    }
    const getOrganizationalList = async (params) => {
      // 获取流程范围中部门列表的函数
      const res = await getDepartmentShow(params)
      const permissionList = res.data
      state.organizationalList = permissionList.length ? permissionList : []
    }
    const onExpand = (expandedKeys) => {
      // 部门列表的父级展开
      state.expandedKeys = expandedKeys
      state.autoExpandParent = false
    }
    const onCheck = (checkedKeys) => {
      // 部门列表的选择回调
      modalVal.power = checkedKeys
    }
    const selectScopeFocus = () => {
      // 处理流程范围获取焦点函数
      state.isSelectopen = true
      state.isClickTab = true
      setTimeout(() => {
        state.isFocus = false
        state.isClickTab = false
      }, 100)
    }
    const selectScopeBlur = () => {
      // 处理流程范围失去焦点函数
      setTimeout(() => {
        state.isFocus = true
      }, 20)
    }
    const selectScopeClick = () => {
      // 处理流程范围下拉框的点击
      !state.isFocus && (state.isSelectopen = !state.isSelectopen)
      state.isClickTab = true
      setTimeout(() => {
        state.isClickTab = false
      }, 80)
    }
    const clickSelectTab = () => {
      // 处理流程范围下拉框的点击
      state.isClickTab = true
      state.isFocus = true
      setTimeout(() => {
        state.isClickTab = false
      }, 100)
    }
    const clickNoTab = () => {
      // 处理点击非流程范围下拉框区域
      if (!state.isClickTab) {
        state.isSelectopen = false
        state.isClickTab = true
      }
    }

    const handleCancel = () => {
      // 取消流程弹窗
      formRef.value.resetFields()
      // emit('update:visible', false)
      router.back()
    }
    const filterSelectProcessRange = (processType, processRangeVal) => {
      // 处理下修改时流程，赋值流程范围
      modalVal.departmentVal = {
        staff: [],
        department: [],
      }
      if (processType === 2) {
        processRangeVal.forEach((item) => {
          item.staffId && modalVal.departmentVal.staff.push(item.staffId)
          item.departmentId && modalVal.departmentVal.department.push(item.departmentId)
        })
      } else {
        modalVal.departmentVal.department.push('sss')
        state.isAllFlowScope = true
      }
    }

    const handleProcessTypeChange = () => {
      if (modalVal.process === 1) {
        modalVal.staffList = []
        initialApproalList()
      }
    }

    const initialization = () => {
      state.isAllFlowScope = false
      modalVal.processName = undefined
      modalVal.business = 1
      modalVal.process = 1
      modalVal.department = undefined
      modalVal.departmentVal = {
        staff: [],
        department: [],
      }
      modalVal.staffList = []
      state.expandedKeys = []
      state.autoExpandParent = false
      state.searchLbwList = state.totalSearchList
      initialApproalList([])
      state.scopeVisible = true
      typeof state.isVirtualListScroll2 === 'number' ? state.isVirtualListScroll2++ : (state.isVirtualListScroll2 = 0)
    }
    const modalSubmit = async (value) => {
      const res = await addProcess(value)
      if (res.success) {
        store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
        cmsNotice('success', '添加成功')
        router.back()
      }
      state.loading = false
    }

    const searchDepartment = (value) => {
      getOrganizationalList({ searchDept: value })
    }

    const radioChange = (e) => {
      state.approvalType = e.target.value
      state.switchValue = false
    }
    const switchChange = (checked) => {
      state.switchValue = checked
    }

    const submit = () => {
      if (state.approvalType == 1) {
        selectApprovalFn()
      } else {
        state.approvalVisible = false
        check.staffList.push({
          type: 2,
          staffId: null,
          id: null,
          avatarId: null,
          staffName: '部门主管',
          name: '部门主管',
          upDpLook: state.switchValue,
        })
        enhancerMapFn.changeStaffList()
      }
    }

    onMounted(() => {
      document.body.addEventListener('click', clickNoTab, false)
      getProcessStaff()
      getOrganizationalList()
      initialization()
    })

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', clickNoTab)
    })

    watch(
      () => state.approvalType,
      (newValue) => {
        if (newValue == 2) {
          check.checkedList = []
          check.checkedListRight = []
          reloadSearchLbwList()
        }
      }
    )

    // watch(
    //   () => props.visible,
    //   (newVisible) => {
    //     if (!newVisible) {
    //       state.scopeVisible = newVisible
    //       return
    //     }
    //     let newCheckStaffList = []
    //     state.isAllFlowScope = false
    //     if (props.types === 'add') {
    //       modalVal.processName = undefined
    //       modalVal.business = 1
    //       modalVal.process = 1
    //       modalVal.department = undefined
    //       modalVal.departmentVal = {
    //         staff: [],
    //         department: [],
    //       }
    //       modalVal.staffList = []
    //       state.expandedKeys = []
    //       state.autoExpandParent = false
    //       state.searchLbwList = state.totalSearchList
    //     }
    //     else if (props.types === 'edit') {
    //       modalVal.processName = props.current.flowName
    //       modalVal.business = props.current.businessType
    //       modalVal.process = props.current.flowType
    //       modalVal.department = '已选择'
    //       filterSelectProcessRange(props.current.flowScope, props.current.flowScopeVO)
    //       modalVal.staffList =
    //         props.current.flowUserRelsVO && props.current.flowUserRelsVO.length
    //           ? props.current.flowUserRelsVO.map((item) => (item.id = item.staffId) && item)
    //           : []
    //       newCheckStaffList = modalVal.staffList.map((item) => item)
    //       state.expandedKeys = []
    //       state.autoExpandParent = false
    //     }
    //     initialApproalList(newCheckStaffList)
    //     state.scopeVisible = newVisible
    //     typeof state.isVirtualListScroll2 === 'number' ? state.isVirtualListScroll2++ : (state.isVirtualListScroll2 = 0)
    //   }
    // )

    return {
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      onExpand,
      ...toRefs(state),
      range,
      onCheck,
      approvalFormRef,
      approvalModel,
      approvalRules,
      selectScopeFocus,
      selectScopeClick,
      selectScopeBlur,
      clickSelectTab,
      selectApprovalFn,
      closeApprovaModel,
      onCheckAllChange,
      onChange,
      check,
      deleteApprovalFn,
      handleCancel,
      searchApprovalList,
      openApprovalModal,
      handleProcessTypeChange,
      modalSubmit,
      onChangeList,
      searchDepartment,
      radioChange,
      switchChange,
      submit,
      handleDepartment,
      handledRangeVisible,
      deleteSta,
      checkData
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.ant-form-item-label) {
  width: 82px;
}
:deep(.ant-select) {
  .ant-select-selection-item-remove {
    display: none;
  }
}
.free-tips {
  margin-left: 82px;
  .text {
    width: 468px;
    height: 34px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    margin: -8px 0 24px 0;
  }
}
:deep(.ant-modal-header) {
  border-bottom: 0;
}
.approval-modal {
  // margin-top: -24px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
:deep(.ant-timeline-item-content) {
  top: 0;
}
.scroll {
  overflow-y: scroll;
  height: 376px;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    // background: #f5f5f5;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}
.no-data {
  height: 355px;
  .no-icon {
    height: 355px;
    transform: translateY(36%);
  }
}

.form-item-error {
  :deep(.has-error .ant-form-explain) {
    display: none;
  }
}

// 抄送人
.form-tips-view {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.printer {
  width: 468px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: #bfbfbf;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  padding: 6px 24px 1px 10px;
  transition: border-color 0.3s;
  &::-webkit-scrollbar {
    display: block;
  }
  &:hover {
    border-color: #cf3838;
  }
  .pt-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 5px 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .pt-name {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }
  .pt-close {
    width: 14px;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #333;
    }
  }
  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
